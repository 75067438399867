<template>
  <div class="vg_wrapper">
    <el-card v-loading='isLoading'>
      <div>
        <el-form
          :inline="true"
          size="min"
          :model="searchForm"
          ref="searchForm"
          @keyup.enter.native="getPstksNow()"
        >
          <el-row>
            <el-col :md="6">
              <el-form-item label="产品编号：">
                <el-input
                  size="small"
                  v-model.trim="searchForm.prod_no"
                  clearable
                  placeholder="请填写产品编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="10">
              <el-form-item label="库位名称：">
                <el-input
                  size="small"
                  v-model.trim="searchForm.pstk_bin"
                  clearable
                  placeholder="请填写库位名称"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="getPstksNow()"
                  class="vg_ml_16"
                  >查询</el-button
                >
                <el-button
                  type="info"
                  size="small"
                  class="vd_export"
                  @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <!-- <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button> -->
      </div>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border>
            <el-table-column label="产品编号" prop="prod_no" />
            <el-table-column label="产品名称" prop="prod_name" />
            <el-table-column label="产品规格" prop="prod_spec" />
            <el-table-column label="库存数量" prop="pstk_num" />
            <el-table-column label="库位" prop="pstk_bin" />
            <el-table-column
              label="修改时间"
              prop="update_time"
              :formatter="formatDate"
            />
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination
            :totalPage="totalPage"
            @changePageSearch="changePageSearch"
            ref="pubPagination"
          ></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { pstkAPI } from "@api/modules/pstk";
// import selectStatus from "@/views/component/selectStatus";
import pubPagination from "@/components/common/pubPagination";

export default {
  name: "PstkList",
  components: {
    // selectStatus,
    pubPagination,
  },
  data() {
    return {
      searchForm: {
        prod_no: "",
        pstk_bin: "",
      },
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      acctFctrGroupOpt: [],
      cpttGroupOpt: [],
      isLoading:true
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === "/pstk_add" || from.path === "/pstk_edit") {
        this.initData();
      }
    },
  },
  methods: {
    initData() {
      this.getPstksList();
    },
    //获取物料采购列表
    getPstksList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(pstkAPI.getPstks, {
        prod_no: this.searchForm.prod_no,
        pstk_bin: this.searchForm.pstk_bin,
        page_no: this.currentPage,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
          setTimeout(()=>{
            this.isLoading = false
          },500)
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        prod_no: "",
        pstk_bin: "",
      };
      this.currentPage = 1;
      this.isLoading = true
      this.initData();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.update_time);
    },
    // 查询方法
    getPstksNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.isLoading = true
      this.getPstksList();
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.$refs.pubPagination.currentPage = 1;
      this.isLoading = true 
      this.getPstksList();
    },
  },
};
</script>

<style scoped>
</style>